import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import './main'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {Helmet} from "react-helmet";
// import "./styles.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default function App() {
 
    return (
        <>
           <Helmet>
    <script src="./assets/js/main.js" 
    type="text/javascript" />
    </Helmet>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className="brand-logo-slider-area section-space--ptb_60">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* brand logo slider */}
                                    <div className="brand-logo-slider__container-area">
                                        <div className="swiper-container brand-logo-slider__container">
                                            <div className="swiper-wrapper brand-logo-slider__one">
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/img/brand-logo/react-gray.png" className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/img/brand-logo/react-blue.png" className="img-fluid" alt="" />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/img/brand-logo/node-gray.png" className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/img/brand-logo/node-blue.png" className="img-fluid" alt="" />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/img/brand-logo/go-gray.png" className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/img/brand-logo/go-blue.png" className="img-fluid" alt="" />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/img/brand-logo/python-gray.png" className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/img/brand-logo/python-blue.png" className="img-fluid" alt="" />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/img/brand-logo/rust-gray.png" className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/img/brand-logo/rust-blue.png" className="img-fluid" alt="" />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/img/brand-logo/solidity-gray.png" className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/img/brand-logo/solidity-blue.png" className="img-fluid" alt="" />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

            </Swiper>
        </>
    );
}
