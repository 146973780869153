import React from 'react'

function BuildingWallet() {
  return (
    <>
    <div className="machine-learning-about-company-area bg-gray section-space--ptb_120">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        {/* section-title-wrap Start */}
        <div className="section-title-wrap text-center section-space--mb_30">
          <h6 className="section-sub-title mb-20">Our Solution</h6>
        </div>
        {/* section-title-wrap Start */}
      </div>
    </div>
    <div className="row">
      <div className="col-lg-6">
        <div className="image-inner-video-section">
          <div className="main-video-box video-popup">
            <div className="single-popup-wrap">
              <img className="img-fluid border-radus-5" src="assets/img/section/smart-contract2.png" width="100%" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 ms-auto mt-30">
        <div className="machine-learning-about-content">
          <div className="section-title mb-20">
            <h4>Building a DeFi wallet smart <span className='text-color-primary'> contract</span></h4>
            <div className="dec-text text-justify mt-20">DeFiwallet depends on the blockchainyou intend to use it on, not all wallets are available on all blockchains and hence you should consider the assets that are supported on the platform.</div>
            <div className="button-box mt-30">
              <a href="#contact-us" className="ht-btn ht-btn-md">Let’s Talk</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default BuildingWallet