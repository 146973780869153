 import React, { useEffect, useState } from 'react'
import { Link, NavLink } from "react-router-dom";

// const useScrollHandler = () => {


export default function Header() {

    const [scroll, setScroll] = useState(0);

    useEffect(() => {
        const onScroll = () => {
            const scrollCheck = window.scrollY > 10;
            setScroll(scrollCheck);
        };

        document.addEventListener("scroll", onScroll);
        return () => {
            document.removeEventListener("scroll", onScroll);
        };
    }, [scroll, setScroll]);

    // const scroll = useScrollHandler();

    const [toggleButton, setToggleButton] = useState(false);
    const [toggleSubmenu, setToggleSubmenu] = useState(false);
    const [toggleSubmenu2, setToggleSubmenu2] = useState(false);
    const [toggleSubmenu3, setToggleSubmenu3] = useState(false);
    const [toggleSubmenu5, setToggleSubmenu5] = useState(false);
    const [toggleSubmenu6, setToggleSubmenu6] = useState(false);

    //     const [toggleActive, setToggleActive] = useState(false);
    //    const pathnames = window.localStorage;
    const pathname = window.location.href;
    const last = pathname.substring(pathname.lastIndexOf("/") + 1, pathname.length);
    // console.log('this is last url ====>', last);
    const url = pathname.slice(22);
    // console.log('this is trim word url', url);
    // console.log('pathname', pathname);

    const nft = ['nft-development', 'nft-marketplace', 'nft-gaming', 'sft-development'];
    const defi = ['defi-solutions', 'defi-wallet', 'defi-yield-farming', 'defi-staking', 'defi-lending', 'defi-lottery', 'dapp-development', 'dao-development'];
    const exchange = ['cryptocurrency-exchange', 'hybrid-crypto-exchange', 'margin-trading-exchange', 'crypto-derivatives-exchange', 'p2p-exchange', 'security-exchange-development', 'decentralized-exchange', 'market-making-services'];
    const blockchain = ['white-paper-development', 'blockchain-solutions-for-enterprise', 'cardano-credits-development','smart-contract-development'];
    const igt = ['about-us', 'media', 'career'];

    const found = nft.find(element => element === last);
    const found2 = defi.find(element => element === last);
    const found3 = exchange.find(element => element === last);
    const found5 = blockchain.find(element => element === last);
    const found6 = igt.find(element => element === last);

    console.log('found url name is ---------->', found2);
    //    useEffect(() => {

    //     setToggleActive(pathname)},[]);

    return (

        <>
            <div className="header-area">
                {/* <div className="header-top-bar-info bg-gray d-none d-lg-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="top-bar-wrap">
                                    <div className="top-bar-left">
                                        <div className="top-bar-text"><a href="#" className="font-medium display-inline">Now Hiring:</a> Are you a driven and motivated 1st Line IT Support Engineer?</div>
                                    </div>
                                    <div className="top-bar-right">
                                        <ul className="top-bar-info">
                                            <li className="info-item">
                                                <i className="info-icon fa fa-map-marker-alt" />
                                                <span className="info-text">1910  Clover Tower, Business Bay, Dubai</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className={scroll ? "header-bottom-wrap header-sticky bg-white is-sticky" : "header-bottom-wrap header-sticky bg-white "} >
                    {/* <div className="header-bottom-wrap  bg-white "> */}
                    <div className="container-fluid--cp-60">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="header position-relative">
                                    {/* brand logo */}
                                    <div className="header__logo ps-0">
                                        <NavLink to="/">
                                            <img src="./igt-it-logo.png" title='Xeer Technology' className="img-fluid" alt="" />
                                        </NavLink>
                                    </div>
                                    <div className="header-right">
                                        {/* navigation menu */}
                                        <div className="header__navigation menu-style-three d-none d-xl-block">
                                            <nav className="navigation-menu">
                                                <ul>
                                                    <li><NavLink reloadDocument to="/crypto-banking-solution"><span>CRYPTO BANKING</span></NavLink></li>
                                                    <li><NavLink reloadDocument to="/metaverse-development" activeClassName="active"><span>METAVERSE</span></NavLink></li>
                                                    <li className={last === found ? "has-children has-children--multilevel-submenu active" : "has-children has-children--multilevel-submenu"}>
                                                        <Link to="#"><span>NFT</span></Link>
                                                        <ul className="submenu">
                                                            <li><NavLink reloadDocument to="/nft-development" ><span>NFT Development</span></NavLink></li>
                                                            <li><NavLink reloadDocument to="/nft-marketplace"><span>NFT Marketplace</span></NavLink></li>
                                                            <li><NavLink reloadDocument to="/nft-gaming"><span>NFT Gaming Platform Development</span></NavLink></li>
                                                            <li><NavLink reloadDocument to="/sft-development"><span>Semi Fungible Token Development</span></NavLink></li>
                                                        </ul>
                                                    </li>
                                                    <li className={last === found2 ? "has-children has-children--multilevel-submenu active" : "has-children has-children--multilevel-submenu"}>
                                                        <a href="#"><span>DEFI</span></a>
                                                        {/* multilevel submenu */}
                                                        <ul className="submenu">
                                                            <li><NavLink reloadDocument to="/defi-solutions"><span>DeFi Solutions</span></NavLink></li>
                                                            <li><NavLink reloadDocument to="/defi-wallet"><span>DeFi Wallet Development</span></NavLink></li>
                                                            <li><NavLink reloadDocument to="/defi-yield-farming"><span>DeFi Yield Farming Development</span></NavLink></li>
                                                            <li><NavLink reloadDocument to="/defi-staking"><span>DeFi Staking Platform</span></NavLink></li>
                                                            <li><NavLink reloadDocument to="/defi-lending"><span>DeFi Lending &amp; Borrowing</span></NavLink></li>
                                                            <li><NavLink reloadDocument to="/defi-lottery"><span>DeFi Lottery Platform Development</span></NavLink></li>
                                                            <li><NavLink reloadDocument to="/dapp-development"><span>dapp Development Services</span></NavLink></li>
                                                            <li><NavLink reloadDocument to="/dao-development"><span>DAO Development</span></NavLink></li>
                                                        </ul>
                                                    </li>
                                                    <li className={last === found3 ? "has-children has-children--multilevel-submenu active" : "has-children has-children--multilevel-submenu"}>
                                                        <a href="#"><span>EXCHANGE</span></a>
                                                        <ul className="submenu">
                                                            <li><NavLink reloadDocument to="/cryptocurrency-exchange"><span>White Lable Exchange</span></NavLink></li>
                                                            <li><NavLink reloadDocument to="/hybrid-crypto-exchange"><span>Hybrid Cryptocourrency Exchange Development</span></NavLink></li>
                                                            <li><NavLink reloadDocument to="/margin-trading-exchange"><span>margin Trading Exchange</span></NavLink></li>
                                                            <li><NavLink reloadDocument to="/crypto-derivatives-exchange"><span>Derivatives Exchange Development</span></NavLink></li>
                                                            <li><NavLink reloadDocument to="/p2p-exchange"><span>P2P Exchange Platform</span></NavLink></li>
                                                            <li><NavLink reloadDocument to="/security-exchange-development"><span>Security Exchange Development</span></NavLink></li>
                                                            <li><NavLink reloadDocument to="/decentralized-exchange"><span>Decentralized Exchange Development</span></NavLink></li>
                                                            <li><NavLink reloadDocument to="/market-making-services"><span>Market Making services</span></NavLink></li>
                                                        </ul>
                                                    </li>
                                                    <li><NavLink reloadDocument to="/cryptocurrency-wallet"><span>WALLET</span></NavLink></li>
                                                    <li><NavLink reloadDocument to="/coin-token-development"><span>COIN DEVELOPMENT</span></NavLink></li>
                                                    <li className={last === found5 ? "has-children has-children--multilevel-submenu active" : "has-children has-children--multilevel-submenu"} s>
                                                        <a href="#"><span>BLOCKCHAIN</span></a>
                                                        <ul className="submenu">
                                                            <li><NavLink reloadDocument to="/blockchain-solutions-for-enterprise"><span>Enterprises Blockchain</span></NavLink></li>
                                                            <li><NavLink reloadDocument to="/smart-contract-development"><span>Smart Contract</span></NavLink></li>
                                                            <li><NavLink reloadDocument to="/carbon-credits-development"><span>Carbon Credits</span></NavLink></li>
                                                            <li><NavLink reloadDocument to="/white-paper-development"><span>Whitepaper Development</span></NavLink></li>
                                                        </ul>
                                                    </li>
                                                    <li><NavLink to="/blog"><span>BLOG</span></NavLink></li>
                                                    <li className={last === found6 ? "has-children has-children--multilevel-submenu active" : "has-children has-children--multilevel-submenu"}>
                                                        <a href="#"><span>Xeer</span></a>
                                                        {/* multilevel submenu */}
                                                        <ul className="submenu">
                                                            <li><NavLink reloadDocument to="/about-us"><span>About Us</span></NavLink></li>
                                                            <li><a href="#"><span>Media</span></a></li>
                                                            <li><a href="#"><span>We are Hiring</span></a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                        <div className="header-search-form-two">
                                            {/* <form action="#" class="search-form-top-active">
                                  <div class="search-icon" id="search-overlay-trigger">
                                      <a href="javascript:void(0)">
                                          <i class="fa fa-search"></i>
                                      </a>
                                  </div>
                              </form> */}
                                        </div>
                                        {/* mobile menu */}
                                        <div className="mobile-navigation-icon d-block d-xl-none" id="mobile-menu-trigger" onClick={() => { setToggleButton(true) }}>
                                            <i />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className={toggleButton ? "mobile-menu-overlay active" : "mobile-menu-overlay "} id="mobile-menu-overlay">
                <div className="mobile-menu-overlay__inner">
                    <div className="mobile-menu-overlay__header">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-md-6 col-8">
                                    {/* logo */}
                                    <div className="logo">
                                        <NavLink to="/">
                                            <img src="./igt-it-logo.png" className="img-fluid" alt="" />
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="col-md-6 col-4">
                                    {/* mobile menu content */}
                                    <div className="mobile-menu-content text-end">
                                        <span className="mobile-navigation-close-icon" onClick={() => setToggleButton(false)} id="mobile-menu-close-trigger" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mobile-menu-overlay__body">
                        <nav className="offcanvas-navigation">
                            <ul>
                                <li><NavLink to="/crypto-banking-solution">CRYPTO BANKING</NavLink></li>
                                <li><NavLink to="/metaverse-development">METAVERSE</NavLink></li>
                                <li className={toggleSubmenu ? "has-children active" : "has-children"} >
                                    <span class="menu-expand" onClick={() => setToggleSubmenu(!toggleSubmenu)}><i></i></span>
                                    <a href="#">NFT</a>
                                    <ul className="sub-menu" style={toggleSubmenu ? {} : { display: "none" }} >
                                        <li><NavLink to="/nft-development"><span>NFT Development</span></NavLink></li>
                                        <li><NavLink to="/nft-marketplace"><span>NFT Marketplace</span></NavLink></li>
                                        <li><NavLink to="/nft-gaming"><span>NFT Gaming Platform Development</span></NavLink></li>
                                        <li><NavLink to="/sft-development"><span>Semi Fungible Token Development</span></NavLink></li>
                                    </ul>
                                </li>
                                <li className={toggleSubmenu2 ? "has-children active" : "has-children"} >
                                    <span class="menu-expand" onClick={() => setToggleSubmenu2(!toggleSubmenu2)}><i></i></span>
                                    <a href="#">DEFI</a>
                                    <ul className="sub-menu" style={toggleSubmenu2 ? {} : { display: "none" }}>
                                        <li><NavLink to="/defi-solutions"><span>DeFi Solutions</span></NavLink></li>
                                        <li><NavLink to="/defi-wallet"><span>DeFi Wallet Development</span></NavLink></li>
                                        <li><NavLink to="/defi-yield-farming"><span>DeFi Yield Farming Development</span></NavLink></li>
                                        <li><NavLink to="/defi-staking"><span>DeFi Staking Platform</span></NavLink></li>
                                        <li><NavLink to="/defi-lending"><span>DeFi Lending  &amp; Borrowing</span></NavLink></li>
                                        <li><NavLink to="/defi-lottery"><span>DeFi Lottery Platform Development</span></NavLink></li>
                                        <li><NavLink to="/dapp-development"><span>dapp Development Services</span></NavLink></li>
                                        <li><NavLink to="/dao-development"><span>DAO Development</span></NavLink></li>
                                    </ul>
                                </li>
                                <li className={toggleSubmenu3 ? "has-children active" : "has-children"} >
                                    <span class="menu-expand" onClick={() => setToggleSubmenu3(!toggleSubmenu3)}><i></i></span>
                                    <a href="#">EXCHANGE</a>
                                    <ul className="sub-menu" style={toggleSubmenu3 ? {} : { display: "none" }}>
                                        <li><NavLink to="/cryptocurrency-exchange"><span>White Lable Exchange</span></NavLink></li>
                                        <li><NavLink to="/hybrid-crypto-exchange"><span>Hybrid Cryptocourrency Exchange Development</span></NavLink></li>
                                        <li><NavLink to="/margin-trading-exchange"><span>Maring Trading Exchange</span></NavLink></li>
                                        <li><NavLink to="/crypto-derivatives-exchange"><span>Derivatives Exchange Development</span></NavLink></li>
                                        <li><NavLink to="/p2p-exchange"><span>P2P Exchange Platform</span></NavLink></li>
                                        <li><NavLink to="/security-exchange-development"><span>Security Exchange Development</span></NavLink></li>
                                        <li><NavLink to="/decentralized-exchange"><span>Decentralized Exchange Development</span></NavLink></li>
                                        <li><NavLink to="/market-making-services"><span>Market Making services</span></NavLink></li>
                                    </ul>
                                </li>
                                <li><NavLink to="/cryptocurrency-wallet">WALLET</NavLink></li>
                                <li><NavLink to="/coin-token-development">COIN DEVELOPMENT</NavLink></li>
                                <li className={toggleSubmenu5 ? "has-children active" : "has-children"} >
                                    <span class="menu-expand" onClick={() => setToggleSubmenu5(!toggleSubmenu5)}><i></i></span>
                                    <a href="javascript:void(0)">BLOCKCHAIN</a>
                                    <ul className="sub-menu" style={toggleSubmenu5 ? {} : { display: "none" }}>
                                        <li><NavLink to="/blockchain-solutions-for-enterprise"><span>Enterprises Blockchain</span></NavLink></li>
                                        <li><NavLink to="/smart-contract-development"><span>Smart Contract</span></NavLink></li>
                                        <li><NavLink to="/carbon-credits-development"><span>Carbon Credits</span></NavLink></li>
                                        <li><NavLink to="/white-paper-development"><span>Whitepaper Development</span></NavLink></li>
                                    </ul>
                                </li>
                                <li><a href="#">BLOG</a></li>
                                <li className={toggleSubmenu6 ? "has-children active" : "has-children"}>
                                    <span class="menu-expand" onClick={() => setToggleSubmenu6(!toggleSubmenu6)}><i></i></span>
                                    <NavLink to="/blog"><span>Xeer</span></NavLink>
                                    {/* multilevel submenu */}
                                    <ul className="sub-menu" style={toggleSubmenu6 ? {} : { display: "none" }}>
                                        <li><NavLink to="/about-us"><span>About Us</span></NavLink></li>
                                        <li><a href="#"><span>Media</span></a></li>
                                        <li><a href="#"><span>We are Hiring</span></a></li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>


        </>
    )
}
