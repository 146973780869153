import React from 'react'

function BlockchainWarraper() {
    return (
        <>
            <div className="feature-large-images-wrapper section-space--ptb_100">
                <div className="container">
                    <div className="cybersecurity-about-box section-space--pb_70">
                        <div className="row">
                            <div className="col-lg-6 align-self-center">
                                <h3>Enterprise <span className='text-blue'> Blockchain</span></h3>
                                <p className="fs-6 mt-3">Blockchain has the potential to revolutionize how businesses and corporate industry work. Several technical professionals and leaders are recognizing this technology and applying it to day-to-day business operations. Enterprise blockchain is an underlying technology to deliver decentralized solutions for enterprises requiring immutability, security, transparency, and traceability.</p>
                            </div>
                            <div className="col-lg-6">
                                <img src="../assets/img/section/enterprise-blockchain.png" alt="" className="img-fluid rounded mx-auto d-block" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default BlockchainWarraper