import React from 'react'

function BlockchainBenefits() {
    return (
        <>
            <div className="blog-section-wrapper section-space--pt_100  section-space--pb_70">
                <div className="container">
                    <div className="row">
                        <h3 className="text-center">Why Enterprises Should Use <span className='text-color-primary'> Blockchain </span></h3>
                        <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                            {/* ht-box-icon Start */}
                            <div className="ht-box-images style-05">
                                <div className="image-box-wrap">
                                    <div className="box-image">
                                        <div className="default-image">
                                            <img className="img-fulid" src="assets/img/section/icon/record-storage.png" alt="" />
                                        </div>
                                        <div className="hover-images">
                                            <img className="img-fulid" src="assets/img/section/icon/record-storage-white.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="content">
                                        <h5 className="heading">Record Storage & Management</h5>
                                        <div className="text">Blockchain technology can change and expand the capacities of various records storing and management processes. It also has far-reaching consequences for cost-effectively protecting and authenticating intellectual property.
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            {/* ht-box-icon End */}
                        </div>
                        <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                            {/* ht-box-icon Start */}
                            <div className="ht-box-images style-05">
                                <div className="image-box-wrap">
                                    <div className="box-image">
                                        <div className="default-image">
                                            <img className="img-fulid" src="assets/img/section/icon/automation.png" alt="" />
                                        </div>
                                        <div className="hover-images">
                                            <img className="img-fulid" src="assets/img/section/icon/automation-white.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="content">
                                        <h5 className="heading">Automation</h5>
                                        <div className="text">Blockchain might offer benefits for automated solutions – both connect a transaction or process which is regulated by the smart contracts.  Once operations are set, smart contracts automatically execute the operations when defined conditions are met.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* ht-box-icon End */}
                        </div>
                        <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                            {/* ht-box-icon Start */}
                            <div className="ht-box-images style-05">
                                <div className="image-box-wrap">
                                    <div className="box-image">
                                        <div className="default-image">
                                            <img className="img-fulid" src="assets/img/section/icon/data-trans.png" alt="" />
                                        </div>
                                        <div className="hover-images">
                                            <img className="img-fulid" src="assets/img/section/icon/data-trans-white.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="content">
                                        <h5 className="heading">Data Transparency & Availability</h5>
                                        <div className="text">Data transparency is one of the biggest promises of blockchain technology, which provides a fully auditable and valid set of records. Blockchain is available to access from anywhere without any time barrier.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* ht-box-icon End */}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default BlockchainBenefits