import React from 'react'
import { Link, NavLink } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
export default function Footer() {
    const todaysDate = new Date()
    const currentYear = todaysDate.getFullYear()

    return (
        <>

            <div className="footer-area-wrapper  bg-gray">
                <div className="footer-area section-space--ptb_80">
                    <div className="container">
                        <div className="row footer-widget-wrapper">
                            {/* <div className="col-lg-3 col-md-12 col-sm-12 footer-widget">
                                <div className="footer-widget__logo mb-15">
                                    <a href="/">
                                        <img src="assets/img/logo/igt-it-logo.png" className="img-fluid" alt="" />
                                    </a>
                                </div>
                                <ul className="footer-widget__list text-end">
                                    <li>IGT IT Solution provides blockchain development and consulting services.</li>
                                    <li>1910 Clover Tower, Business Bay, Dubai</li>
                                    <li><a href="#" className="hover-style-link">info@igtitsolutions.com</a></li>
                                    <li><a href="#" className="hover-style-link text-black font-weight--bold">(+68)1221 09876</a></li>
                                </ul>
                            </div> */}

                            <div className="col-lg-4 col-md-6 col-sm-12 footer-widget">
                                <h6 className="footer-widget__title mb-20 text-blue">Quick Links</h6>
                                <ul className="footer-widget__list">
                                    {/* <li><a href="/Crypto-banking-solution" className="hover-style-link">Crypto Banking</a></li> */}
                                    <li><NavLink reloadDocument to="/dapp-development" className="hover-style-link">dApp Development</NavLink></li>
                                    <li><NavLink reloadDocument to="smart-contract-development" className="hover-style-link">Smart Contract Development</NavLink></li>
                                    <li><NavLink reloadDocument to="/cryptocurrency-exchange" className="hover-style-link">White Label Exchange </NavLink></li>
                                    <li><NavLink reloadDocument to="/decentralized-exchange" className="hover-style-link">Decentralized Exchange Development</NavLink></li>
                                    <li><NavLink reloadDocument to="/coin-token-development" className="hover-style-link">Coin/Token Development</NavLink></li>
                                    <li><NavLink reloadDocument to="/nft-development" className="hover-style-link">NFT Development</NavLink></li>
                                    <li><NavLink reloadDocument to="/cryptocurrency-wallet" className="hover-style-link">Crypto Wallet Development</NavLink></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 footer-widget">
                                <h6 className="footer-widget__title mb-20 text-blue">Services</h6>
                                <ul className="footer-widget__list">
                                    {/* <li><a href="/Crypto-banking-solution" className="hover-style-link">Crypto Banking</a></li> */}
                                    {/* <li><NavLink reloadDocument to="" className="hover-style-link" style={{color:"black"}}> Development</NavLink></li>
                                    <li><NavLink reloadDocument to="" className="hover-style-link" style={{color:"black"}}> Consulting</NavLink></li>
                                    <li><NavLink reloadDocument to="" className="hover-style-link">Marketing</NavLink></li>
                                    <li><NavLink reloadDocument to="" className="hover-style-link">Contribution </NavLink></li>
                                    <li><NavLink reloadDocument to="" className="hover-style-link">IT Management</NavLink></li> */}
                                    <li><a href="#"  className="hover-style-link"> Development</a></li>
                                    <li><a href="#"  className="hover-style-link">  Consultin</a></li>
                                    <li><a href="#"  className="hover-style-link"> Marketing</a></li>
                                    <li><a href="#"  className="hover-style-link"> Contribution</a></li>
                                    <li><a href="#"  className="hover-style-link"> IT Management</a></li>
                                </ul>
                            </div>


                            <div className="col-lg-2 col-md-6 col-sm-12 footer-widget">
                                <h6 className="footer-widget__title mb-20 text-blue">Support</h6>
                                <ul className="footer-widget__list">

                                    <li><a href="/AboutUs" className="hover-style-link">About Us</a></li>
                                    <li><a href="#faq" className="hover-style-link">FAQs</a></li>
                                    <li><a href="#" className="hover-style-link">Career</a></li>
                                    <li><a href="#contact-us" className="hover-style-link">Our Portfolio</a></li>
                                    <li><a href="#contact-us" className="hover-style-link">Contact Us</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 footer-widget">
                                <h6 className="footer-widget__title mb-20 text-blue">UAE Office</h6>
                                <ul className="footer-widget__list">
                                    <li> <span className='text-black '> Address :</span> Business Bay, Dubai</li>
                                    <li> <span className='text-black '> Telegram :</span> <a href="https://t.me/xeertechnology/">@xeertechnology</a></li>
                                    <li><a href="mailto:info@xeertechnology.com" className="hover-style-link"> info@xeertechnology.com</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright-area section-space--pb_30">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 text-center text-md-start">
                                 <span className="copyright-text">XEER Technology <b>©</b> {currentYear} <a href="#">All Rights Reserved.</a></span>
                                
                               </div>
                            <div className="col-md-6 text-center text-md-end">
                                <ul className="list ht-social-networks solid-rounded-icon">
                                   
                                    <li className="item">
                                        <a href="https://www.facebook.com/people/Xeer-Xeer/pfbid0Y3c1JGUqGLUmUnoP2EegUsGayVuueuiFJEu2BdKWwKug4TXsXmYGSsvvp29bMQcyl/" target="_blank" aria-label="Facebook" className="social-link hint--bounce hint--top hint--primary">
                                            <i className="fab fa-facebook-f link-icon" />
                                        </a>
                                    </li>
                                    <li className="item">
                                        <a href="https://www.instagram.com/xeer.technology/" target="_blank" aria-label="Instagram" className="social-link hint--bounce hint--top hint--primary">
                                            <i className="fab fa-instagram link-icon" />
                                        </a>
                                    </li>
                                    <li className="item">
                                        <a href="https://www.linkedin.com/company/xeer-technology" target="_blank" aria-label="Linkedin" className="social-link hint--bounce hint--top hint--primary">
                                            <i className="fab fa-linkedin link-icon" />
                                        </a>
                                    </li>
                                    <li className="item">
                                        <a href="https://join.skype.com/invite/VkdsW3AKvnUP" target="_blank" aria-label="Skype" className="social-link hint--bounce hint--top hint--primary">
                                            <i className="fab fa-skype link-icon" />
                                        </a>
                                    </li>
                                    <li className="item">
                                        <a href="https://t.me/xeertechnology/" target="_blank" aria-label="Telegram" className="social-link hint--bounce hint--top hint--primary">
                                            <i className="fab fa-telegram link-icon" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <ScrollToTop className='ScrollToTop' smooth /> */}
            <a href="#" className='ScrollToTop' id="toTop">
                <i class="fa fa-angle-up icon" aria-hidden="true"></i>

                {/* <i className="arrow-bottom fal fa-long-arrow-up"></i> */}
            </a>
        </>
    )
}
