import React from 'react'

function TradingInfo() {
    return (
        <>

            <div className="contact-us-info-wrappaer section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                                <h3 className="heading">Platform Security & <span className='text-color-primary'> Risk Management </span></h3>
                                <p className="fs-5">We develop leverage and margin trading software with risk management features integrated into it, to safeguard exchange owners and traders against losses.</p>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-3 col-md-6">
                            <div className="conact-info-wrap mt-30">
                                <img src="assets/img/section/icon/stop-loss.png" className="my-2" alt="" />
                                <h5 className="heading ">Stop Loss/Take Profit</h5>
                                <p>Allowing traders to set floor and ceiling price for execute or terminate an order automatically.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="conact-info-wrap mt-30">
                                <img src="assets/img/section/icon/delevegring.png" className="my-2" alt="" />
                                <h5 className="heading ">Auto Deleveraging</h5>
                                <p>Automatically liquidating traders’ position when asset price reaches the predefined value.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="conact-info-wrap mt-30">
                                <img src="assets/img/section/icon/insurance.png" className="my-2" alt="" />
                                <h5 className="heading ">Insurance Funds</h5>
                                <p>Insuring funds is protection to the assets on the platform to be safe in such situation in liquidation.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="conact-info-wrap mt-30">
                                <img src="assets/img/section/icon/partial.png" className="my-2" alt="" />
                                <h5 className="heading ">Partial Close Orders</h5>
                                <p>Enabling users to partially close orders to take their profits and continue to benefit from the market.</p>
                            </div>
                        </div>
                        {/* <div className="button-box d-flex flex-wrap mt-5 justify-content-center">
                            <a href="#" className="ht-btn m-2 ht-btn-md">Talk to a consultant</a>
                        </div> */}
                    </div>
                </div>
            </div>


        </>
    )
}

export default TradingInfo