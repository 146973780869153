import React from 'react'

export default function Howwork() {
    return (
        <>
            <div className="claim-to-excel-area section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_60">
                                <h2 className="heading">How do we <span className='text-color-primary'> work! </span></h2>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="claim-grid-group-3 justify-content-center">
                                <div className="ht-box-icon style-04">
                                    <div className="icon-box-wrap color-one">
                                        <div className="icon">
                                            <span className="icon-basic-anticlockwise" />
                                        </div>
                                        <div className="content">
                                            <h6 className="sub-heading">01</h6>
                                            <h5 className="heading">Project Idea Discussion</h5>
                                            <p className="text">
                                                We give prompt help desk answer right when receiving
                                                the request from customers.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="ht-box-icon style-04">
                                    <div className="icon-box-wrap color-two">
                                        <div className="icon">
                                            <span className="icon-basic-life-buoy" />
                                        </div>
                                        <div className="content">
                                            <h6 className="sub-heading">02</h6>
                                            <h5 className="heading">Conceptualization &amp; Product Blue Print</h5>
                                            <p className="text">
                                                Knowledge come with age and time. Our experts make
                                                sure it works.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="ht-box-icon style-04">
                                    <div className="icon-box-wrap color-three">
                                        <div className="icon">
                                            <span className="icon-basic-world" />
                                        </div>
                                        <div className="content">
                                            <h6 className="sub-heading">03</h6>
                                            <h5 className="heading">Development, Testing &amp; Amendments </h5>
                                            <p className="text">
                                                No risking factors are present and we have all kinds
                                                of measures to protect against losses.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="ht-box-icon style-04">
                                    <div className="icon-box-wrap color-four">
                                        <div className="icon">
                                            <span className="icon-basic-case" />
                                        </div>
                                        <div className="content">
                                            <h6 className="sub-heading">04</h6>
                                            <h5 className="heading">Final Execution &amp; Product Release</h5>
                                            <p className="text">
                                                Technological wise plans are made for addressing all
                                                kinds of requests and demands.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row--10">
                        <div className="col-lg-8">
                            <div className="success-stories-wrap mt-40">
                                <div className="modern-number-01 me-5">
                                    <h2 className="heading"><span className="mark-text text-color-primary">15</span></h2>
                                    <p>YEARS IN THE FIELD</p>
                                </div>
                                <div className="content">
                                    <h4 className="heading mb-20">
                                        Let’s not  and   <br />
                                        waste time<br />
                                        connect!
                                    </h4>
                                    <a href="#contact-us" className="ht-btn ht-btn-md"><i className="far fa-comment-alt-dots me-2" />
                                        Let's
                                        talk</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="modern-it-newsletters-wrap text-center mt-40">
                                <div className="section-title">
                                    <h6 className="heading mb-30">
                                        Stay up to date in the industry with our newsletter
                                    </h6>
                                </div>
                                <form action="#">
                                    <div className="contact-inner">
                                                {/* <input name="con_name" type="text" placeholder="Name *" /> */}
                                                <div class="animate-label">
                                               <input type="text" className='input' style={{border:"none"}} id="username" required/>
                                               <label for="username" style={{marginLeft:"-110px"}}> Email </label>
                                               
                                                 <line></line>
                                                 </div>

                                        {/*  */}
                                        {/* <input name="con_email" type="email" placeholder="Your e-mail" /> */}
                                    </div>
                                    <div className="button-wrap mb-15">
                                        <a href="#contact-us" className="ht-btn ht-btn-md ht-btn-full">Subscribe</a>
                                    </div>
                                    <p>Join 89.898 subscribers.</p>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="testimonial-slider-area bg-gray section-space--ptb_100" style={{ background: '#fff' }}>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 text-center">
                            <div className="section-title-wrap text-center section-space--mb_40">
                                <h3 className="heading">
                                    What requires to build NFT
                                    <span className="text-color-primary"> Marketplace!</span>
                                </h3>
                            </div>
                            <p className="text-center fs-6 mb-4 text-dark">
                                Building a NFT marketplace from scratch requires creating of different components and merging it all together with one smart contract.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
