import React from 'react'

export default function Hero() {
    return (
        <>
            <div className="cybersecurity-hero processing-hero-bg-2">
                <div className="container-fluid ">
                    <div className="row ">
                        {/*baseline*/}
                        <div className="col-lg-7 col-sm-12 order-lg-2 align-self-center">
                            <div>
                            {/* <img src="assets/img/banners/Blockchain-powered-digital-asse-icon.png" className='img-10 ' width="55%" alt="" /> */}

                                <img src="assets/img/banners/blockchain-powered-digital-asse-back.png" width="100%" alt="" />
                                {/* <img src="assets/img/banners/Blockchain-powered-digital-asset.png" width="100%" alt="" /> */}
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 col-sm-12 align-self-center">
                            <div className="cybersecurity-hero-text wow move-up">
                                <h6>Let’s build a</h6>
                                <h3 className="font-weight--reguler">
                                    Blockchain powered
                                    <span className="text-color-primary"> digital asset </span> platform for crypto banking
                                </h3>
                                <div className="d-flex flex-wrap hero-button mt-30">
                                    {/* <a href="#contact-us" className="ht-btn m-2 ht-btn-md">See Demo
                                    </a> */}
                                    <a href="#contact-us" className="ht-btn m-2 ht-btn-md">Discuss
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
