import React from 'react'
import Typewriter from "typewriter-effect";
export default function Herobanner() {
    return (
        <>
            <div className="processing-hero processing-hero-bg-1">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-center">
                        {/*baseline*/}
                        <div className="col-lg-6 col-md-6">
                            <div className="processing-hero-text wow move-up">
                                <div>
                                    <h6 className='text-dark'>We are </h6>
                                    <h1 className="font-weight--reguler mb-15 text-dark">Developing &amp; Consulting
                                        <span className="text-color-secondary">
                                            <Typewriter
                                                options={{
                                                    strings: [
                                                        "dApps",
                                                        "Blockchain",
                                                        "Smart Contract",
                                                        "Exchange",
                                                    ],
                                                    autoStart: true,
                                                    loop: true,
                                                    delay: 40,
                                                    deleteSpeed: 40,
                                                    typeSpeed: 40,
                                                }}
                                            />
                                        </span></h1>
                                    <p>Connect with Experienced Developers &amp; Professionals <br /> From  Web3 Industry</p>
                                    <div className="hero-button mt-30">
                                        <a href="#contact-us" className="btn btn--primary rounded-pill">Contact Us</a>
                                        {/* <a href="#contact-us" className="bubbly-button">Contact Us</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 ">
                            <div class="processing-hero-images-wrap wow move-up">
                                <div class="processing-hero-images">
                                    <img src="./assets/img/hero/xeer-phone.png" width="80%" alt=""  srcset="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
