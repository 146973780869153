import React from 'react'

function FinancialTransactions() {
    return (
        <>

<div className="machine-learning-about-company-area section-space--ptb_120">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        {/* section-title-wrap Start */}
        <div className="section-title-wrap text-center section-space--mb_30">
          <h6 className="section-sub-title mb-20">About decentralized finance</h6>
        </div>
        {/* section-title-wrap Start */}
      </div>
    </div>
    <div className="row">
      <div className="col-lg-6">
        <div className="image-inner-video-section">
          <div className="main-video-box video-popup">
            <div className="single-popup-wrap">
              <img className="img-fluid border-radus-5 img-sec five" src="assets/img/section/defi-about-icon.png" alt="" />
              <img className="img-fluid border-radus-5" src="assets/img/section/defi-about-back.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-5 ms-auto mt-30 align-self-center">
        <div className="machine-learning-about-content">
          <div className="section-title mb-20">
            <h5>Defilets participants make financial transactions without any central authority </h5>
            <p className="dec-text mt-20">It’s an emerging field that cut out the middleman and make financial transactions directly p2p and it’s quickly gaining in popularity as an alternative to traditional financial services. DeFi already lets you do most things offered by traditional banks and centralized financial institutions, with new products and transactions available each day.</p>
            {/* <div class="button-box mt-30">
                                      <a href="#" class="ht-btn ht-btn-md">Read Full Blog</a>
                                  </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        </>
    )
}

export default FinancialTransactions 