import React from 'react'

function Banner() {
  return (
    <>

<div className="cybersecurity-her processing-hero-bg__color ">
  <div className="container">
    <div className="row align-items-center">
      {/*baseline*/}
      <div className="col-lg-6 col-md-7 align-self-center">
        <div className="cybersecurity-hero-text wow move-up">
          <h6>The new era of</h6>
          <h3 className="font-weight--reguler mb-30">Decentralized  <span className="text-color-primary">finance and the financial applications</span> of blockchain technology</h3>
        </div>
      </div>
      <div className="col-lg-6 col-md-5">
        <div className="cybersecurity-hero-images-wrap wow move-up">
          <div className="cybersecurity-hero-images section-space--mb_80">
            <div className="inner-img-two">
            <img className="img-fluid  ml-5  defi-img-b" src="assets/img/banners/defi-banner-back.png" alt="" />
            <img className="img-fluid  ml-5  six " style={{marginLeft:"-8px",marginTop:"5px"}} src="assets/img/banners/defi-banner-icon.png"  alt=""   />
            
              {/* <img className="img-fluid  ml-5 " src="assets/img/banners/defi-banner.png" alt="" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default Banner