import React from 'react'

function FarmingInfo() {
    return (
        <>

            <div className="machine-learning-about-company-area section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                                <h3 className="section-heading mb-20">What is DeFi <span className='text-blue'> Yield Farming! </span></h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 ms-auto mt-10">
                            <div className="machine-learning-about-content">
                                <div className="section-title text-center mb-20">
                                    {/* <img src="assets/images/wallet-blue-circle-icon-vector-21964622.jpg" width="100px" alt=""> */}
                                    <p className="dec-text">Yield farming is the process of using crypto assets to maximize returns in decentralized finance. Users lend or borrow differentassets on a DeFi platform and earn assets in return.Users who want to increase their yield can use more complex tactics and models such as providing liquidity to a pool and staking LP tokens.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default FarmingInfo