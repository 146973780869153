import React from 'react'
import Contact1 from '../comman/Contact1'
import Faq from './Faq'
import Footer from '../comman/Footer'
import Header from '../comman/Header'
import Aboutmetaverse from './Aboutmetaverse'
import Hero from './Hero'
import Makemetaverse from './Makemetaverse'
import Weaim from './Weaim'
import Whyigtit from './Whyigtit'
export default function Metaversedevelopment() {
  return (
    <>
      <div id="main-wrapper" style={{ overflow: 'hidden' }}>
        <div className="site-wrapper-reveal">
          <Header />
          <Hero />
          <Aboutmetaverse />
          <Weaim />
          {/* <Whyigtit /> */}
          <Makemetaverse />
          <Faq />
          <Contact1 />
          <Footer />
        </div>
      </div>
     
    </>
  )
}
