import React from 'react'

export default function Contact1() {
    return (
        <>
            <div className="contact-us-area appointment-contact-bg section-space--ptb_100 " id="contact-us">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="contact-title section-space--mb_50">
                                <h3 className="mb-20">Want to connect?</h3>
                                <p className="sub-title">Reach out to us with your firm intoduction!</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contact-form-wrap">
                                {/* <form class="contact-form" id="contact-form-2" action="https://whizthemes.com/mail-php/jowel/mitech/php/services-mail.php" method="post"> */}
                                <form className="contact-form" id="contact-form-2" >
                                    <div className="contact-form__two">
                                        <div className="contact-input">
                                            <div className="contact-inner">
                                                {/* <input name="con_name" type="text" placeholder="Name *" /> */}
                                                <div class="animate-label">
                                               <input type="text" className='input' id="username" required/>
                                               <label for="username"> Email or phone </label>
                                               
                                                 <line></line>
                                                 </div>
                                            </div>
                                            <div className="contact-inner">
                                                {/* <input name="con_email" type="email" placeholder="Email *" /> */}
                                                <div class="animate-label">
                                                <input type="password" className='input' id="password"  required/>
                                                 <label for="password"> Password  </label>
                                                   <line></line>
                                                      </div>
                                            </div>
                                        </div>
                                        <div className="contact-select">
                                            <div className="form-item contact-inner">
                                                <span className="inquiry">
                                                    <select id="Visiting" name="Visiting">
                                                        <option disabled selected>Select Department to email</option>
                                                        <option value="Your inquiry about">General Inquiry</option>
                                                        <option value="General Information Request">Partnership</option>
                                                        <option value="Partner Relations">Career Oriented</option>
                                                        <option value="Careers">Software Licencing</option>
                                                        <option value="Software Licencing">Other</option>
                                                    </select>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="contact-inner contact-message">
                                            <textarea name="con_message" placeholder="Please describe what you need." defaultValue={""} />
                                        </div>
                                        <div className="comment-submit-btn">
                                            <button className="ht-btn ht-btn-md" type="submit" disabled>Get a free consultation</button>
                                            {/* <p className="form-messege-2" /> */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-5 ms-auto">
                            <div className="contact-info-three text-left">
                                <h6 className="heading font-weight--reguler">Reach out now!</h6>
                                <h5 className="call-us"><a href="https://join.skype.com/invite/vKbdeFGDjEQU" target="_blank">Xeer Technology</a></h5>
                                <div className="text text-justify">We offer blockchain-based product development and consulting services across entire web3 industry. We have built dozens of projects in this domain including a whole blockchain network, decentralized exchange (DEX), staking platforms and other numerous dApps.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
