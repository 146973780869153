import React from 'react'

function SmartContractDataInfo() {
    return (
        <>

            <div className="feature-images-wrapper bg-gray section-space--ptb_100 service_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_30">
                                <h4 className="heading">Where Smart Contracts Are <span className="text-color-primary">
                                Implemented</span></h4>
                                <p className="mt-3 text-center mx-5">The application of smart contract is across several sectors including financial purposes like trading, investing, lending, and borrowing. It can also be used for gaming, healthcare, real estate and such in case of configuring entire corporate structures.</p>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="feature-images__four">
                        <div className="row justify-content-center">
                            
                            <div className="single-item col-lg-4 col-md-4 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <img className="img-fulid w-100" src="../assets/img/section/enterprise-blockchain-applications/finance.png" alt="" />
                                <h6 className="mt-3">Finance</h6>
                                <p className="second_section_info mt-3">The most financial use case of smart contract is a new generation of financial services without the need for centralized custody or fees from intermediaries. DeFi sector is only a few years old yet the innovative dApps that are already providing value and utility to consumers. The effects of smart-contract-powered dApps on the financial industry are already being felt.</p>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-4 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                <img className="img-fulid w-100" src="../assets/img/section/enterprise-blockchain-applications/legal.png" alt="" />
                                <h6 className="mt-3">Legal</h6>
                                <p className="second_section_info mt-3">Technology has been driving innovation in the legal industry, most recently with the advent of e-signatures for binding legal agreements. One of the most promising use case of smart contract is their potential to function as legally binding contracts, the kind that inform most of today’s business engagements.</p>
                            </div>
                            <div className="single-item col-lg-4 col-md-4 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <img className="img-fulid w-100" src="../assets/img/section/enterprise-blockchain-applications/real-estate.png" alt="" />
                                <h6 className="mt-3">Real-estate</h6>
                                <p className="second_section_info mt-3">With tokenization, smart contracts are advancing the fractional ownership of assets and thus lowering the barrier to entry for investment for many by merging blockchain and real estate transactions.</p>
                                {/* ht-box-icon End */}
                            </div>
                        </div>
                        <div className="row justify-content-center mt-5">
                            <div className="single-item col-lg-4 col-md-4  mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <img className="img-fulid w-100" src="../assets/img/section/enterprise-blockchain-applications/gaming.png" alt="" />
                                <h6 className="mt-3">Gaming</h6>
                                <p className="second_section_info mt-3">Blockchain technology in gaming is commonly driven by non-fungible tokens (NFTs) — unique digital assets that represent in-game content. These NFTs rely on smart contracts. In contrast, smart contracts in gaming can enable players to capture the utility and value of in-game purchases and asset acquisitions more effectively.</p>
                                {/* ht-box-icon End */}
                            </div>
                            <div className="single-item col-lg-4 col-md-4 mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                <img className="img-fulid w-100" src="../assets/img/section/enterprise-blockchain-applications/government.png" alt="" />
                                <h6 className="mt-3">Government</h6>
                                <p className="second_section_info mt-3">Governments can explore more smart contract use cases based on their requirements. It could help the government in automation and to manage operations. One of those operations includes land title recording where the government can use it to do property transfers. Other such use-cases for the government are in elections, digital identity and record filing.</p>
                            </div>
                            <div className="single-item col-lg-4 col-md-4  mt-30 wow move-up animated animated" style={{ visibility: 'visible' }}>
                                {/* ht-box-icon Start */}
                                <img className="img-fulid w-100" src="../assets/img/section/enterprise-blockchain-applications/healthcare-pharmacy.png" alt="" />
                                <h6 className="mt-3">Medical & Healthcare</h6>
                                <p className="second_section_info mt-3">Medical & healthcare companies have an enormous amount of data which includes test results and new drug formulas, that could be stored and managed efficiently using smart contracts on blockchain.</p>
                                {/* ht-box-icon End */}
                            </div>
                        </div>
                        <div className="row justify-content-center mt-5">
                            <div className="single-item col-lg-4 col-md-4  mt-30 wow move-up" style={{ visibility: 'hidden', animationName: 'none' }}>
                                {/* ht-box-icon Start */}
                                <img className="img-fulid w-100" src="../assets/img/section/enterprise-blockchain-applications/supply-chain.png" alt="" />
                                <h6 className="mt-3">Logistic</h6>
                                <p className="second_section_info mt-3">Smart contracts simplify and secure many of the processes in the logistics and transportation industry, including such agreement terms, fraud protection, record keeping, payments, cash flow, and more. They also save money, since they eliminate the need for any third-party processors.</p>
                            </div>
                            <div className="single-item col-lg-4 col-md-4  mt-30 wow move-up" style={{ visibility: 'hidden', animationName: 'none' }}>
                                {/* ht-box-icon Start */}
                                <img className="img-fulid w-100" src="../assets/img/section/enterprise-blockchain-applications/media-entertainment.png" alt="" />
                                <h6 className="mt-3">Media & Entertainment</h6>
                                <p className="second_section_info mt-3">In media, smart contract could be utilized for the distribution and management of content in a highly secure manner. It can also be applied in terms that meet predetermined conditions, such regarding release of royalties to the right recipient every time their content is consumed.</p>
                            </div>
                           
                        </div>
                        <p className="fs-4 text-center mt-5">Have an idea to build a smart contract for?</p>
                            <div className="button-box mt-30 text-center">
                                <a href="#contact-us" className="ht-btn ht-btn-md">Contact Us</a>
                            </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default SmartContractDataInfo