import React from 'react'

export default function Hero() {
    return (
        <>
            <div className="machine-learning-about-company-area machine-learning-about-b section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-left section-space--mb_30">
                                <h6 className="section-sub-title mb-20">About NFT</h6>
                                <h2 className="heading">NFTs are the new <span className="text-color-primary">art</span>
                                </h2>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="image-inner-video-section">
                                <div className="main-video-box video-popup">
                                    <div className="single-popup-wrap">
                                        <img className="img-fluid border-radus-5 img-sec  five"  src="assets/img/section/hero-section2.png" alt="" />
                                        <img className="img-fluid border-radus-5"  src="assets/img/section/hero-back.png" alt="" />
                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 ms-auto mt-30">
                            <div className="machine-learning-about-content">
                                <div className="section-title mb-20">
                                    <h4>NFT has brought a evolution in art</h4>
                                    <p className="dec-text mt-20">NFT is a digital asset that represents Internet collectibles like art, music, and games with an authentic certificate created by blockchain technology that underlies Cryptocurrency. </p>
                                    <div class="button-box mt-30">
                                  <a href="#" class="ht-btn ht-btn-md">Read Full Blog</a>
                              </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
