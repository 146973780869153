import React from 'react'

export default function  BlockchainApplication() {
    return (
        <>
            <div className="feature-large-images-wrapper section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_60">
                                {/* <h6 className="section-sub-title mb-20">Hire us, why not?</h6> */}
                                <h3 className="heading">Enterprise Blockchain <span className="text-color-primary"> Applications</span></h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                    {/* ht-box-icon Start */}
                                    <div className="ht-large-box-images style-03">
                                        <div className="large-image-box">
                                            <div className="box-image">
                                                <div className="default-image">
                                                    <img className="img-fluid" src="assets/img/section/enterprise-blockchain-applications/banking.png" alt="" />
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h5 className="heading">Banking</h5>
                                                <div className="text">Blockchain technology in the banking field could revolutionize the whole banking experience, as the technology has made the presence of a wide variety of options available with a single click.</div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    {/* ht-box-icon End */}
                                </div>
                                <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                    {/* ht-box-icon Start */}
                                    <div className="ht-large-box-images style-03">
                                        <div className="large-image-box">
                                            <div className="box-image">
                                                <div className="default-image">
                                                    <img className="img-fluid" src="assets/img/section/enterprise-blockchain-applications/supply-chain.png" alt="" />
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h5 className="heading">Supply Chain</h5>
                                                <div className="text">Blockchain technology can facilitate accurate asset tracking, enhanced licensing of services, products, and transparency into the provenance of consumer goods, from source to the point of consumption.</div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    {/* ht-box-icon End */}
                                </div>
                                <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                    {/* ht-box-icon Start */}
                                    <div className="ht-large-box-images style-03">
                                        <div className="large-image-box">
                                            <div className="box-image">
                                                <div className="default-image">
                                                    <img className="img-fluid" src="assets/img/section/enterprise-blockchain-applications/finance01.png" alt="" />
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h5 className="heading">Finance</h5>
                                                <div className="text">Current financial services struggle with processes such as slow payments, limited transparency, and security vulnerabilities. Blockchain enhances the efficient digitization of financial instruments, which increases liquidity and lowers cost of capital.</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ht-box-icon End */}
                                </div>
                                <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                    {/* ht-box-icon Start */}
                                    <div className="ht-large-box-images style-03">
                                        <div className="large-image-box">
                                            <div className="box-image">
                                                <div className="default-image">
                                                    <img className="img-fluid" src="assets/img/section/enterprise-blockchain-applications/agriculture.png" alt="" />
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h5 className="heading">Agriculture</h5>
                                                <div className="text">Blockchain technology in the agriculture industry is used  to enhance the number of reliable information available about inventory, the agricultural sector, and the overall status of the farms. </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ht-box-icon End */}
                                </div>
                                <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                    {/* ht-box-icon Start */}
                                    <div className="ht-large-box-images style-03">
                                        <div className="large-image-box">
                                            <div className="box-image">
                                                <div className="default-image">
                                                    <img className="img-fluid" src="assets/img/section/enterprise-blockchain-applications/insurance.png" alt="" />
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h5 className="heading">Insurance</h5>
                                                <div className="text">Blockchain can securely streamline data verification, claims processing, and disbursement, reducing processing time significantly in providing insurance.</div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    {/* ht-box-icon End */}
                                </div>
                                <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                    {/* ht-box-icon Start */}
                                    <div className="ht-large-box-images style-03">
                                        <div className="large-image-box">
                                            <div className="box-image">
                                                <div className="default-image">
                                                    <img className="img-fluid" src="assets/img/section/enterprise-blockchain-applications/media-entertainment.png" alt="" />
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h5 className="heading">Media & Entertainment</h5>
                                                <div className="text">In the media industry, blockchain technology can track the life cycle of digital content and facilitate the distribution of authentic digital collectibles. It could help in fixing the entertainment in the same manner.</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ht-box-icon End */}
                                </div>
                                <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                    {/* ht-box-icon Start */}
                                    <div className="ht-large-box-images style-03">
                                        <div className="large-image-box">
                                            <div className="box-image">
                                                <div className="default-image">
                                                    <img className="img-fluid" src="assets/img/section/enterprise-blockchain-applications/healthcare-pharmacy.png" alt="" />
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h5 className="heading">Healthcare & Pharmacy</h5>
                                                <div className="text">Solutions built with Blockchain in the healthcare industry will enable faster, highly efficient, and more secure medical data management and supply tracking.</div>
                                                <br /><br />
                                            </div>
                                        </div>
                                    </div>
                                    {/* ht-box-icon End */}
                                </div>
                                <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                    {/* ht-box-icon Start */}
                                    <div className="ht-large-box-images style-03">
                                        <div className="large-image-box">
                                            <div className="box-image">
                                                <div className="default-image">
                                                    <img className="img-fluid" src="assets/img/section/enterprise-blockchain-applications/environmental.png" alt="" />
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h5 className="heading">Environmental</h5>
                                                <div className="text">Energy efficient blockchain-based smart contracts enable the creation of globally accessible and fully automated incentive systems that directly reward individuals, companies, and governments for participating in sustainable practices.</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ht-box-icon End */}
                                </div>
                                <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                    {/* ht-box-icon Start */}
                                    <div className="ht-large-box-images style-03">
                                        <div className="large-image-box">
                                            <div className="box-image">
                                                <div className="default-image">
                                                    <img className="img-fluid" src="assets/img/section/enterprise-blockchain-applications/retail.png" alt="" />
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h5 className="heading">Retail</h5>
                                                <div className="text">The retail industry is rapidly changing with higher demands of growth in consumers. Blockchain stands to address long-standing industry challenges by improving data management tools, enhancing supply chain operations.</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ht-box-icon End */}
                                </div>
                                <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                    {/* ht-box-icon Start */}
                                    <div className="ht-large-box-images style-03">
                                        <div className="large-image-box">
                                            <div className="box-image">
                                                <div className="default-image">
                                                    <img className="img-fluid" src="assets/img/section/enterprise-blockchain-applications/real-estate.png" alt="" />
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h5 className="heading">Real Estate</h5>
                                                <div className="text">Enterprise Blockchain enables the digitization of assets and financial instruments which lead in enhancing fractionalization of ownership, expanded access to global markets, increased liquidity, and democratized access to real estate investment opportunities.</div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    {/* ht-box-icon End */}
                                </div>
                                <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                    {/* ht-box-icon Start */}
                                    <div className="ht-large-box-images style-03">
                                        <div className="large-image-box">
                                            <div className="box-image">
                                                <div className="default-image">
                                                    <img className="img-fluid" src="assets/img/section/enterprise-blockchain-applications/sports-gaming.png" alt="" />
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h5 className="heading">Sports & Gaming</h5>
                                                <div className="text">Smart contracts built with blockchain can streamline existing e-sports operations, provide new revenue models, and enhance fan engagement with enhanced loyalty programs and incentives. Sporting data and records can also be stored on blockchain.</div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    {/* ht-box-icon End */}
                                </div>
                                <div className="col-lg-4 col-md-6 wow move-up animated" style={{ visibility: 'visible' }}>
                                    {/* ht-box-icon Start */}
                                    <div className="ht-large-box-images style-03">
                                        <div className="large-image-box">
                                            <div className="box-image">
                                                <div className="default-image">
                                                    <img className="img-fluid" src="assets/img/section/enterprise-blockchain-applications/public-sector.png" alt="" />
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h5 className="heading">Public Sector</h5>
                                                <div className="text">The public sector use-cases of Blockchain technology will build trust, improve accountability and responsiveness, increase efficiency, reduce costs, and create high-performing government applications with more secure, agile, and cost-effective structures.</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ht-box-icon End */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
