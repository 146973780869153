import React from 'react'

function CoinDevelopementBenefits() {
    return (
        <>
            <div className="feature-images-wrapper section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_40">
                                <h4 className="heading">Blockchain Networks to<span className="text-color-primary">&nbsp;Launch Token!
                                </span>
                                </h4>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="feature-images__serven">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 py-4 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07 Benefits_box border">
                                            <div className="image-box-wrap ">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/launch-token-logo/ethereum.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 py-4 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07 border">
                                            <div className="image-box-wrap ">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/launch-token-logo/binance.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 py-4 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07 Benefits_box border">
                                            <div className="image-box-wrap ">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/launch-token-logo/eos.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 py-4 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07 Benefits_box border">
                                            <div className="image-box-wrap ">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/launch-token-logo/hyperledger.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 py-4 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07 border">
                                            <div className="image-box-wrap ">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/launch-token-logo/tron.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 py-4 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07 Benefits_box border">
                                            <div className="image-box-wrap ">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/launch-token-logo/cardano.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 py-4 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07 Benefits_box border">
                                            <div className="image-box-wrap ">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/launch-token-logo/polkadot.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 py-4 wow move-up">
                                        {/* ht-box-icon Start */}
                                        <div className="ht-box-images style-07 Benefits_box border">
                                            <div className="image-box-wrap ">
                                                <div className="box-image">
                                                    <div className="default-image">
                                                        <img className="img-fulid" src="./assets/img/section/launch-token-logo/soloana.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ht-box-icon End */}
                                    </div>
                                    <div className='row justify-content-center'>
                                        <div className="col-lg-3 col-md-6 col-sm-12 py-4 wow move-up">
                                            {/* ht-box-icon Start */}
                                            <div className="ht-box-images style-07 border">
                                                <div className="image-box-wrap ">
                                                    <div className="box-image">
                                                        <div className="default-image">
                                                            <img className="img-fulid" src="./assets/img/section/launch-token-logo/polygon.png" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ht-box-icon End */}
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12 py-4 wow move-up">
                                            {/* ht-box-icon Start */}
                                            <div className="ht-box-images style-07 Benefits_box border">
                                                <div className="image-box-wrap ">
                                                    <div className="box-image">
                                                        <div className="default-image">
                                                            <img className="img-fulid" src="./assets/img/section/launch-token-logo/tezos.png" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ht-box-icon End */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </div>

        </>
    )
}

export default CoinDevelopementBenefits