import React from 'react'

function CryptoFeatures () {
  return (
   <>

<div className="feature-images-wrapper bg-gradient section-space--ptb_100">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        {/* section-title-wrap Start */}
        <div className="section-title-wrap text-center section-space--mb_60">
          <h2 className="heading fs-2">Security Options For Our White Label Cryptocurrency Exchange</h2>
        </div>
        {/* section-title-wrap Start */}
      </div>
    </div>
    <div className="image-l-r-box">
      <div className="row">
        <div className="col image-box-area">
          <div className="row image-box-boder-box">
            <div className="col-lg-4 col-md-6 image-box-boder">
              {/* ht-box-icon Start */}
              <div className="ht-box-images style-09" style={{background: '#0d6efd'}}>
                <div className="image-box-wrap">
                  <div className="box-image">
                    <img src="assets/img/section/icon/login-pre.png" className="img-fluid" alt="" />
                  </div>
                  <div className="content text-white">
                    <h6 className="heading text-white">Login Prevention </h6>
                    <div className="text">Preventing accounts to login with multiple failed attempts. 
                    </div>
                  </div>
                </div>
              </div>
              {/* ht-box-icon End */}
            </div>
            <div className="col-lg-4 col-md-6 image-box-boder">
              {/* ht-box-icon Start */}
              <div className="ht-box-images style-09">
                <div className="image-box-wrap">
                  <div className="box-image">
                    <img src="assets/img/section/icon/http.png" className="img-fluid" alt="" />
                  </div>
                  <div className="content">
                    <h6 className="heading">HTTP Parameter Pollution Protection</h6>
                    <div className="text">Deters web attacks that craft an HTTP request in order to retrieve hidden information.
                    </div>
                  </div>
                </div>
              </div>
              {/* ht-box-icon End */}
            </div>
            <div className="col-lg-4 col-md-6 image-box-boder">
              {/* ht-box-icon Start */}
              <div className="ht-box-images style-09" style={{background: '#0d6efd'}}>
                <div className="image-box-wrap">
                  <div className="box-image">
                    <img src="assets/img/section/icon/data-encryp.png" className="img-fluid" alt="" />
                  </div>
                  <div className="content text-white">
                    <h6 className="heading text-white">Data Encryption</h6>
                    <div className="text">Encryption of data to protect users’information and credentials.
                    </div>
                  </div>
                </div>
              </div>
              {/* ht-box-icon End */}
            </div>
            <div className="col-lg-4 col-md-6 image-box-boder">
              {/* ht-box-icon Start */}
              <div className="ht-box-images style-09">
                <div className="image-box-wrap">
                  <div className="box-image">
                    <img src="assets/img/section/icon/anti-dos.png" className="img-fluid" alt="" />
                  </div>
                  <div className="content">
                    <h6 className="heading">AntiDoS & DDoS</h6>
                    <br />
                    <div className="text">Protecting exchange from large access requests sent to the server from multiple sources.
                    </div>
                  </div>
                </div>
              </div>
              {/* ht-box-icon End */}
            </div>
            <div className="col-lg-4 col-md-6 image-box-boder">
              {/* ht-box-icon Start */}
              <div className="ht-box-images style-09" style={{background: '#212121'}}>
                <div className="image-box-wrap">
                  <div className="box-image">
                    <img src="assets/img/section/icon/server.png" className="img-fluid" alt="" />
                  </div>
                  <div className="content text-white">
                    <h6 className="heading text-white">Server-Side Request Forgery (SSRF) Protection</h6>
                    <div className="text">Protects internal systems from attacks sent from pregnable web applications.
                    </div>
                  </div>
                </div>
              </div>
              {/* ht-box-icon End */}
            </div>
            <div className="col-lg-4 col-md-6 image-box-boder">
              {/* ht-box-icon Start */}
              <div className="ht-box-images style-09" >
                <div className="image-box-wrap">
                  <div className="box-image">
                    <img src="assets/img/section/icon/cross-site.png" className="img-fluid" alt="" />
                  </div>
                  <div className="content ">
                    <h6 className="heading ">Cross-Site Request Forgery (CSRF) Protection</h6>
                    <div className="text">Prevents state-changing requests and other unwanted user actions.
                    </div>
                  </div>
                </div>
              </div>
              {/* ht-box-icon End */}
            </div>
           
          </div>
          {/* <div className="feature-list-button-box mt-40 text-center">
            <a href="#" className="ht-btn ht-btn-md">Learn more</a>
          </div> */}
        </div>
      </div>
    </div>
  </div>
</div>

   
   
   </>
  )
}

export default CryptoFeatures 