import React from 'react'

export default function Nftpurose() {
  return (
    <>
      <div className="machine-learning-about-company-area machine-learning-about-b section-space--ptb_120">
        <div className="container">
          <div className="row">
          </div>
          <div className="row">
            <div className="col-lg-7 col-sm-12 ms-auto mt-30">
              <div className="col-lg-12 ">
                {/* section-title-wrap Start */}
                <div className="section-title-wrap text-left section-space--mb_30 ">
                  <h6 className="section-sub-title mb-20">Application of NFT</h6>
                  <h3 className="heading">For what purpose all these <br /> <span className='text-color-primary'> NFTs are used for! </span>
                  </h3>
                </div>
                {/* section-title-wrap Start */}
              </div>
              <div className="machine-learning-about-content">
                <div className="section-title mb-20">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 wow move-up animated" style={{ visibility: 'visible' }}>
                      <div className="fun-fact--three text-center">
                        <div className="fs-4 text-start text-dark fw-bold"><i className="fa fa-check-circle" /> Digital Art</div>
                        <h6 className="fun-fact__text text-start">In form of digital art for showcase and artistic collection.</h6>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 wow move-up animated" style={{ visibility: 'visible' }}>
                      <div className="fun-fact--three text-center">
                        <div className="fs-4 text-start text-dark fw-bold"><i className="fa fa-check-circle" /> Community</div>
                        <h6 className="fun-fact__text text-start">To have a unique identity for members who are part of a particular community.</h6>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 wow move-up animated" style={{ visibility: 'visible' }}>
                      <div className="fun-fact--three text-center">
                        <div className="fs-4 text-start text-dark fw-bold"><i className="fa fa-check-circle" /> Real-time Use</div>
                        <h6 className="fun-fact__text text-start">It also used in real time events such as an entry ticket, digital card etc.</h6>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 wow move-up animated" style={{ visibility: 'visible' }}>
                      <div className="fun-fact--three text-center">
                        <div className="fs-4 text-start text-dark fw-bold"><i className="fa fa-check-circle" /> Platform Utility</div>
                        <h6 className="fun-fact__text text-start">Using NFT property as utility token in gaming, metaverse and other online platforms.</h6>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="button-box mt-30 d-flex flex-wrap">
                        {/* s<a href="#contact-us" className="ht-btn m-2 ht-btn-md">Talk to a consultant</a> */}
                        <a href="#contact-us" className="ht-btn m-2 ht-btn-md">Talk to a consultant</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-sm-12  align-self-center">
              <img src="assets/img/section/for-what-purpose.png" width="100%" alt="" />
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
