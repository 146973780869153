import React from 'react'

function DaoBanner() {
    return (
        <>

            <div className="breadcrumb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-sm-12  align-self-center">
                            <h4 className="heading mt-5">
                                We Consult us in the development of <br />
                                your <span className='text-blue'> Digital Autonomous Organization (DAO) </span>
                            </h4>
                            <div className="button-box mt-4">
                                <a href="#contact-us" className="ht-btn ht-btn-md">Talk to us</a>
                            </div>
                        </div>
                        <div className="col-lg-5 col-sm-12">
                            <img src="assets/img/banners/dao-banner.png" width="350px" alt="" />
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default DaoBanner