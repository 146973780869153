import React from 'react'
import Header from '../../comman/Header'

export default function Learnmarketplace() {
    return (
        <>
            <div className="fun-fact-wrapper section-space--ptb_100">
                <div className='container'>
                    <div className="fun-fact-wrapper section-space--ptb_100">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12 align-self-center">
                                <div className="modern-number-01 me-5">
                                    <h3 className="heading">
                                        Learn how NFT Marketplace work and what you
                                        <span className="text-color-primary">&nbsp; need to build one!</span>
                                    </h3> 
                                    {/* <p>Connect to our expert team members who can guide you through your journey of building and growing an NFT marketplace from scratch. </p> */}
                                </div>
                                <div className="section-under-heading section-space--mt_30">
                                    Connect to our expert team members who can guide you through
                                    your journey of building and growing an NFT marketplace from
                                    scratch.
                                    <a href="#contact-us">Contact us!</a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <img  src="assets/img/section/nft-marketplace.png" width="100%" alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}
